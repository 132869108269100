<template>
  <div class="news-wrap">
    <vue-nav activeIndex='news'></vue-nav>
    <div class="bana">
      <img src="../assets/images/news_banl.png" alt="">
    </div>
    <div class="profit">
      <h2>您是否遇到以下开店<span>难题？</span></h2>
      <img src="../assets/images/news_1_03.jpg" alt="">
    </div>
    <!-- 试用 -->
    <div class="section5">
      <h2>美业汇全方位深入解决美业门店经营管理难题 </h2>
      <h2>实现智慧门店管理，赋能业绩提升</h2>
      <div class="inp">
        <el-input class="isinput" v-model="input" placeholder="请输入手机号"></el-input>
        <el-button @click="open()">免费试用</el-button>
      </div>
    </div>
    <!-- 解决美容院的9大 痛点-->
    <div class="se6">
      <h2>解决美容院的<span> 9 </span>大痛点</h2>
      <div class="se6_box">
        <div class="left">
          <p class="bianhua" @mouseenter="section2Enter(1)" :class="ismouseen==1?'red':''">
            <img src="../assets/images/g_03.gif" alt="" v-show="ismouseen!=1">
            <strong>不盈利</strong>
          </p>
          <p class="bianhua" @mouseenter="section2Enter(2)" :class="ismouseen==2?'red':''">
            <img src="../assets/images/g_03.gif" alt="" v-show="ismouseen!=2">
            <strong>业绩低</strong>
          </p>
          <p class="bianhua" @mouseenter="section2Enter(3)" :class="ismouseen==3?'red':''">
            <img src="../assets/images/g_03.gif" alt="" v-show="ismouseen!=3">
            <strong>缺顾客</strong>
          </p>
          <p class="bianhua" @mouseenter="section2Enter(4)" :class="ismouseen==4?'red':''">
            <img src="../assets/images/g_03.gif" alt="" v-show="ismouseen!=4">
            <strong>预约顾客难</strong>
          </p>
          <p class="bianhua" @mouseenter="section2Enter(5)" :class="ismouseen==5?'red':''">
            <img src="../assets/images/g_03.gif" alt="" v-show="ismouseen!=5">
            <strong>顾客价值低</strong>
          </p>
          <p class="bianhua" @mouseenter="section2Enter(6)" :class="ismouseen==6?'red':''">
            <img src="../assets/images/g_03.gif" alt="" v-show="ismouseen!=6">
            <strong>任务难完成</strong>
          </p>
          <p class="bianhua" @mouseenter="section2Enter(7)" :class="ismouseen==7?'red':''">
            <img src="../assets/images/g_03.gif" alt="" v-show="ismouseen!=7">
            <strong>员工价值低</strong>
          </p>
          <p class="bianhua" @mouseenter="section2Enter(8)" :class="ismouseen==8?'red':''">
            <img src="../assets/images/g_03.gif" alt="" v-show="ismouseen!=8">
            <strong>丢钱丢货</strong>
          </p>
          <p class="bianhua" @mouseenter="section2Enter(9)" :class="ismouseen==9?'red':''">
            <img src="../assets/images/g_03.gif" alt="" v-show="ismouseen!=9">
            <strong>无数据分析</strong>
          </p>
        </div>
        <div class="center">
          <img src="../assets/images/cen_03.gif" alt="">
        </div>
        <div class="right">
          <img :src='"../assets/images/"+ismouseen+".gif"' alt="">
        </div>
      </div>
    </div>
    <!-- 一体化美业解决方案 -->
    <div class="section3">
      <img src='../assets/image/news3.png' alt="">
      <div class="sect3">
      </div>
    </div>
    <!-- 服务和保障 -->
    <div class="section8">
      <h2>服务和保障</h2>
      <ul class="set8list">
        <li>
          <h3>服务保障</h3>
          <p>保证不动老板的钱</p>
          <p>保证不动老板的顾客</p>
        </li>
        <li>
          <h3>售后保障</h3>
          <p>国内最专业保障，支持到店落地培训 </p>
          <p>一对一售后跟踪，客户满意度100%</p>
        </li>
        <li>
          <h3>技术保障</h3>
          <p>系统每周更新一次，</p>
          <p>技术保障接受商家需求反馈，</p>
          <p>不断升级迭代和完善产品</p>
        </li>
        <li>
          <h3>运营保障</h3>
          <p>分析诊断门店经营状况</p>
          <p>量身定制门店营销方案</p>
        </li>
        <li class="two2">
          <h3>安全保障</h3>
          <p>阿里云云端数据存储，</p>
          <p>签订商业安全协议，</p>
          <p>为用户数据提供金融级安全保障</p>
        </li>
        <li>
          <h3>效率保障</h3>
          <p>保证顾客管理、员工管理、</p>
          <p> 门店管理效率成倍提升</p>
        </li>
        <li>
          <h3>服务保障</h3>
          <p>保证去掉中间层，</p>
          <p>使管理成本大幅度降低</p>
        </li>
      </ul>
    </div>
    <!-- 验证 -->
    <el-dialog title="服务热线：136-0200-1885" :visible.sync="centerDialogVisible" width="800px" class="islog">
      <p></p>
      <h2>免费试用</h2>
      <h3>请准确填写您的信息，方便安排专业顾问和您联系！</h3>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="您的姓名" prop="realname">
          <el-input v-model="ruleForm.realname" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input class="isdesc" v-model="ruleForm.code" placeholder="请输入验证码"></el-input>
          <el-button type="primary" @click="submit" class="paics">发送验证码</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
import bnav from './components/b-nav'
export default {
  components: {
    'vue-nav': bnav,
  },
  data () {
    return {
      ismouseen: 1,
      input: '',
      centerDialogVisible: false,
      ruleForm: {
        realname: '',
        mobile: '',
        code: ''
      },
      rules: {
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },

        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      }
    }
  },
  mounted () {

  },
  methods: {
    section2Enter (key) {
      this.ismouseen = key
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('发送成功');
          this.sub()
        }
      });
    },
    open () {
      this.centerDialogVisible = true
      this.ruleForm.mobile = this.input
    },
    sub () {
      var data = this.ruleForm
      console.log(data);

      this.$http.submit(data).then(res => {
        this.$message({
          message: res,
          type: 'success'
        });
        this.centerDialogVisible = false
      })

    },
    submit () {
      var data = {}
      data.mobile = this.ruleForm.mobile
      this.$http.feilogin(data).then(res => {
        this.$message({
          message: res,
          type: 'success'
        });
      })
    }
  }
}
</script>
<style lang="scss" scoped >
@import "../assets/news.scss";
</style>